import { PublicAxios } from '@/utils/publicAxios'
const $ = new PublicAxios()

// --------------------------------------------
// 设备绑定API接口
// --------------------------------------------

/**
 * 获取单兵绑定列表
 * @param {Object} data 详看接口文档
 * Boolean => isLoad 是否需要加载框 默认为false
 */
export const getInd = async data => {
  return await $.get({
    url: 'admin/bind/singleList',
    data,
    isLoad: true,
  })
}

/**
 * 获取绑定下拉框
 */
export const getBSelect = async () => {
  return await $.get({
    url: 'admin/bind/singleBind',
  })
}

/**
 * 保存绑定设备
 * @param {Object} data 详看接口文档
 */
export const addInd = async data => {
  return await $.post({
    url: 'admin/bind/singleAdd',
    data,
  })
}

/**
 * 获取绑定单兵详情
 * @param {String} id 绑定单兵id
 */
export const getIndDetail = async id => {
  return await $.get({
    url: 'admin/bind/singleEdit',
    data: { id },
  })
}

/**
 * 编辑单兵详情
 * @param {Object} data 详看接口文档
 */
export const updIndDetail = async data => {
  return await $.post({
    url: 'admin/bind/singleEdit',
    data,
  })
}

/**
 * 下载单兵绑定模板
 */
export const downloadBind = async () => {
  return await $.get({
    url: 'admin/template/equipTemp',
  })
}

/**
 * 导出
 */
export const expIndBind = async () => {
  return await $.get({
    url: 'admin/bind/singleOut',
  })
}

/**
 * 导入
 * @param {File} file 文件
 */
export const impIndBind = async file => {
  return await $.upload({
    url: 'admin/bind/singleImport',
    file,
  })
}

/**
 * 删除绑定单兵
 * @param {String} id 绑定id
 */
export const delIndBind = async id => {
  return await $.get({
    url: 'admin/bind/singleDel',
    data: { id },
  })
}

/**
 * 获取手环
 * @param {Object} data 详看接口文档
 */
export const getHandBind = async data => {
  return await $.get({
    url: 'admin/bind/braceletList',
    data,
  })
}

/**
 * 获取手环设备
 */
export const getHBSelect = async () => {
  return await $.get({
    url: 'admin/bind/braceletBind',
  })
}

/**
 * 添加手环
 * @param {Object} data 详看接口文档
 */
export const addHand = async data => {
  return await $.post({
    url: 'admin/bind/braceletAdd',
    data,
  })
}

/**
 * 获取手环详情
 * @param {String} id 手环id
 */
export const getHandDetail = async id => {
  return await $.get({
    url: 'admin/bind/braceletEdit',
    data: { id },
  })
}

/**
 * 更新手环详情
 * @param {Object} data 详看接口文档
 */
export const updHand = async data => {
  return await $.post({
    url: 'admin/bind/braceletEdit',
    data,
  })
}

/**
 * 删除绑定手环
 * @param {String} id 绑定id
 */
export const delHand = async id => {
  return await $.get({
    url: 'admin/bind/braceletDel',
    data: { id },
  })
}

/**
 * 获取摄像头
 * @param {Object} data 详看接口文档
 */
export const getVideoBind = async data => {
  return await $.get({
    url: 'admin/bind/cameraList',
    data,
    isLoad: true,
  })
}

/**
 * 获取摄像头下拉
 */
export const getVideoSelect = async () => {
  return await $.get({
    url: 'admin/bind/cameraBind',
  })
}

/**
 * 添加摄像头
 * @param {Object} data 详看接口文档
 */
export const addVideo = async data => {
  return await $.post({
    url: 'admin/bind/cameraAdd',
    data,
  })
}

/**
 * 更新摄像头
 * @param {Object} data 详看接口文档
 */
export const updVideo = async data => {
  return await $.post({
    url: 'admin/bind/cameraEdit',
    data,
  })
}

/**
 * 获取摄像头详情
 * @param {String} id 摄像头id
 */
export const getVideoDetaul = async id => {
  return await $.get({
    url: 'admin/bind/cameraEdit',
    data: { id },
  })
}

/**
 * 删除摄像头
 * @param {String} id 摄像头id
 */
export const delVideo = async id => {
  return await $.get({
    url: 'admin/bind/cameraDel',
    data: { id },
  })
}

/**
 * 获取气体列表
 * @param {Object} data 接口
 */
export const getGas = async data => {
  return await $.get({
    url: 'admin/bind/toxicgasList',
    data,
  })
}

/**
 * 删除
 * @param {String} id 气体
 */
export const delGas = async id => {
  return await $.get({
    url: 'admin/bind/toxicgasDel',
    data: { id },
  })
}

/**
 * 添加气体
 * @param {Object} data 详看接口文档
 */
export const addGas = async data => {
  return await $.post({
    url: 'admin/bind/toxicgasAdd',
    data,
  })
}

/**
 * 更新气体
 * @param {Object} data 详看接口文档
 */
export const updGas = async data => {
  return await $.post({
    url: 'admin/bind/toxicgasEdit',
    data,
  })
}

/**
 * 获取气体详情
 * @param {String} id 气体id
 */
export const getGasDetaul = async id => {
  return await $.get({
    url: 'admin/bind/toxicgasEdit',
    data: { id },
  })
}

/**
 * 获取气体下拉框
 */
export const getGasSelect = async () => {
  return await $.get({
    url: 'admin/bind/toxicgasBind',
  })
}

/**
 * 获取环境列表
 * @param {Object} data 接口
 */
export const getSur = async data => {
  return await $.get({
    url: 'admin/bind/environList',
    data,
  })
}

/**
 * 删除
 * @param {String} id 环境
 */
export const delSur = async id => {
  return await $.get({
    url: 'admin/bind/environDel',
    data: { id },
  })
}

/**
 * 添加环境
 * @param {Object} data 详看接口文档
 */
export const addSur = async data => {
  return await $.post({
    url: 'admin/bind/environAdd',
    data,
  })
}

/**
 * 更新环境
 * @param {Object} data 详看接口文档
 */
export const updSur = async data => {
  return await $.post({
    url: 'admin/bind/environEdit',
    data,
  })
}

/**
 * 获取环境详情
 * @param {String} id 气体id
 */
export const getSurDetaul = async id => {
  return await $.get({
    url: 'admin/bind/environEdit',
    data: { id },
  })
}

/**
 * 获取环境下拉框
 */
export const getSurSelect = async () => {
  return await $.get({
    url: 'admin/bind/environBind',
  })
}

/**
 * 获取人脸列表
 * @param {Object} data 接口
 */
export const getFace = async data => {
  return await $.get({
    url: 'admin/bind/faceList',
    data,
  })
}

/**
 * 删除
 * @param {String} id 人脸
 */
export const delFace = async id => {
  return await $.get({
    url: 'admin/bind/faceDel',
    data: { id },
  })
}

/**
 * 添加人脸
 * @param {Object} data 详看接口文档
 */
export const addFace = async data => {
  return await $.post({
    url: 'admin/bind/faceAdd',
    data,
  })
}

/**
 * 更新人脸
 * @param {Object} data 详看接口文档
 */
export const updFace = async data => {
  return await $.post({
    url: 'admin/bind/faceEdit',
    data,
  })
}

/**
 * 获取人脸详情
 * @param {String} id 气体id
 */
export const getFaceDetaul = async id => {
  return await $.get({
    url: 'admin/bind/faceEdit',
    data: { id },
  })
}
